<template>

  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-table  noDataText="" pagination max-items="50" stripe search :data="fournisseurs">
        <template slot="header">
        </template>
        <template slot="thead">
          <vs-th sort-key="Nom">
            Fournisseur
          </vs-th>
          <vs-th>
            Adresse
          </vs-th>
          <vs-th>
            Téléphone
          </vs-th>
          <vs-th>
            Email
          </vs-th>
          <vs-th>
            Site internet
          </vs-th>
          <vs-th>
            Date livraison
          </vs-th>
          <vs-th>
            Actions
          </vs-th>

        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td>
              {{tr.Nom}}
            </vs-td>
            <vs-td>
              {{tr.Adresse ? tr.Adresse.adresse : ''}}
            </vs-td>
            <vs-td>
              {{tr.Adresse ? tr.Adresse.Telephone : ''}}
            </vs-td>
            <vs-td>
              {{tr.Adresse ? tr.Adresse.Email : ''}}
            </vs-td>
            <vs-td>
              {{tr.Adresse ? tr.Adresse.web : ''}}
            </vs-td>
            <vs-td>
              {{tr.DelaiLiv | moment("calendar", "July 10 2011")}}
            </vs-td>
            <vs-td>
              <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord(tr._id)" />
                <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr)" />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>

</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      idFour: null
    }
  },
  computed: {
    fournisseurs () {
      return this.$store.state.fournisseur.fournisseurs
    }
  },
  methods: {
    editRecord (id) {
      this.$router.push(`/apps/fournisseur/edit/${id}`).catch(() => {})
    },
    confirmDeleteRecord (data) {
      this.idFour = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Vous allez supprimer le fournisseur  "${data.Nom}"`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch('fournisseur/dropFournisseur', this.idFour)
        .then(() => {
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'Supprimer',
        text: 'Le fournisseur selectionné a bien été supprimée'
      })
    },
    getFournisseurs () {
      this.$store.dispatch('fournisseur/getFournisseurs').catch(err => { console.error(err) })
    }
  },
  created () {
    this.getFournisseurs()
  }
}

</script>

<style lang="scss">
#page-fournisseur-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
